.pagesRichText {

    strong {
        font-weight: 500;
        color:       #00448b;
    }

    a {
        font-weight: 500;
        color:       #00448b;
    }

}
