h2.headline-2 {
    @apply font-medium text-primary tracking-tight uppercase text-xl;
}


h3.headline-3 {
    @apply font-medium text-primary uppercase text-sm md:text-base mb-1 md:mb-3 ;
}

label.input-label {
    @apply font-thin text-neutral-400 uppercase text-xs;
}

p {

    @apply text-sm text-neutral-500 leading-relaxed;

    a {
        @apply text-primary font-medium underline;
    }
}
