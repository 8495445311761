:root {
    --toastify-color-primary: #00448b;
    --toastify-color-light: #4184CD;
    --toastify-color-dark: #121212;
    --toastify-color-info: #00448b;
    --toastify-color-success: #368A03;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #D9001A;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: #ffffff;
    --toastify-icon-color-success: #ffffff;
    --toastify-icon-color-warning: #ffffff;
    --toastify-icon-color-error: #ffffff;

    --toastify-toast-width: 50%;
    --toastify-toast-background: #000000;
    --toastify-toast-min-height: 45px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: 'Maven\\ Pro', sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #fff;
    --toastify-text-color-dark: #fff;

    //Used only for colored theme
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    // Used when no type is provided
    // toast("**hello**")
    --toastify-color-progress-light: linear-gradient(
                                            to right,
                                            #4cd964,
                                            #5ac8fa,
                                            #007aff,
                                            #34aadc,
                                            #5856d6,
                                            #ff2d55
                            );
    // Used when no type is provided
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: #ffffff;
    --toastify-color-progress-success: #ffffff;
    --toastify-color-progress-warning: #ffffff;
    --toastify-color-progress-error: #ffffff;
}
