button.button-primary {
    @apply  bg-primary hover:bg-primary-dark text-sm text-white h-min w-full uppercase flex flex-row justify-center items-center px-4 min-h-[37px] rounded font-thin tracking-wider gap-2;

    svg {
        @apply text-white;
    }

}

button.button-neutral {
    @apply  bg-neutral-400 hover:bg-neutral-500 text-sm text-white h-min w-full uppercase flex flex-row justify-center items-center px-4 min-h-[37px] rounded font-thin tracking-wider gap-2;

    svg {
        @apply text-white;
    }
}

button.button-green {
    @apply  bg-green-600 hover:bg-green-700 text-sm text-white h-min w-full uppercase flex flex-row justify-center items-center px-4 min-h-[37px] rounded font-thin tracking-wider gap-2;

    svg {
        @apply text-white;
    }
}

button.button-clear {
    @apply  h-min w-full text-sm uppercase flex flex-row justify-center items-center px-4 min-h-[37px] rounded font-thin tracking-wider gap-2;

    svg {
        @apply text-white;
    }

}
