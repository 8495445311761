@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts";
@import "typo";
@import "buttons";
@import "animations";
@import "ProductDetails";
@import "legacyContent";
@import "reactToastify";
@import "toastColors";
@import "toast";
@import "pages";
@import "cmsContent";
@import "../../../app-card/styles/cardContentStyles";



body {
    background: #ffffff;
    overflow-x: hidden;
    //overflow-y: hidden;
}


@layer utilities {
    .buttonDefault {
        @apply px-4 py-2 border items-center tracking-wider border-transparent cursor-pointer text-sm font-medium rounded-sm text-invert bg-primary hover:bg-primary;
    }
}

* {
    text-rendering:              optimizeLegibility;
    -webkit-font-smoothing:      antialiased;
    -moz-osx-font-smoothing:     grayscale;
    -webkit-tap-highlight-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;

    @apply focus:ring-0;
}


