.cms-content {
  table {
    border: 1px solid black;

    th {
      background: #eeeeee;
      border: 1px solid black;
      padding: 4px 8px;
    }

    td {
      border: 1px solid black;
      padding: 4px 8px;
    }
  }

  figcaption {
    @apply text-sm pt-2;
  }

  strong {
    font-weight: 500;
    color: #00448b;
  }

  a {
    font-weight: 500;
    color: #00448b;
  }

  ul {
    @apply pt-4;
    li {
      @apply list-square ml-4 text-base;
    }
  }

  blockquote {
    @apply pl-4 border-l-4 border-primary-light text-neutral-500 italic mt-4;

    &::before {
      content: "❝";
      @apply text-5xl text-neutral-300 relative;
    }
  }
}
