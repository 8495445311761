.ProductDetails {

    > h1 {
        @apply text-primary text-3xl font-medium flex w-full;
    }

    > h2 {
        @apply text-secondary text-3xl font-medium flex w-full mb-2;
    }

    p {
        @apply py-2 text-neutral-600 text-base leading-relaxed;
    }

}


.SpecificationsText {

    @apply pl-3 text-neutral-500;

    h5 {
        @apply text-secondary font-medium;
    }

    ul {
        li {
            @apply list-none;
        }
    }


}
