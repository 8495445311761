/* The animation code */
@keyframes hoverUpDown {
    0%   {transform: translateY(0)}
    50%   {transform: translateY(20px)}
    100%   {transform: translateY(0)}
}


.animationHoverUpDown {
    animation: hoverUpDown;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}


@keyframes hoverLeftToRight {
    0%   {transform: translatex(-100px)}
    50%   {transform: translatex(100px)}
    100%   {transform: translatex(-100px)}
}


.animationHoverLeftToRight {
    animation: hoverLeftToRight;
    animation-duration: 24s;
    animation-iteration-count: infinite;
}
